import { StoryblokComponent } from '@storyblok/react'
import {
  storyblokEditable,
  StoryblokServerComponent,
} from '@storyblok/react/rsc'
import { clsx } from 'clsx'
import { type CSSProperties, type FC, useMemo } from 'react'

import { useAppRouterMigrationContext } from '../../../app-router-migration'
import { SliderButton, useSliderNavigation } from '../../../slider'
import { type StoryblokComponentProps } from '../../model'
import { type StoryblokSliderType } from '../../model/storyblokTypes.generated'
import { getNonEmptyStyleStringValue } from '../../styles/getNonEmptyStyleStringValue'

import styles from './StoryblokSlider.module.css'

export const StoryblokSlider: FC<
  StoryblokComponentProps<StoryblokSliderType>
> = ({ blok }) => {
  const {
    content,
    desktopGap,
    desktopItemsPerPage,
    mobileGap,
    mobileItemsPerPage,
    tabletGap,
    tabletItemsPerPage,
  } = blok

  const { isAppRouter } = useAppRouterMigrationContext()

  const Renderer = isAppRouter ? StoryblokServerComponent : StoryblokComponent

  const { containerRef, leftRef, rightRef } =
    useSliderNavigation<HTMLUListElement>()

  /**
   * Children components are wrapped with `Suspense`. In combination with `useSliderNavigation`
   * which uses `useState` hook for managing `ref`s it cause multiple re-rendering in React 19.
   * As a side effect JavaScript logic related to navigation button display could be broken.
   * In addition, children components could re-render with visual shifts.
   *
   * To resolve the issue memoize the children component output to avoid re-rendering.
   */
  const slides = useMemo(
    () =>
      content?.map((item) => {
        const { _uid: key } = item

        return (
          <li className={styles.slider__item} key={key}>
            <Renderer blok={item} />
          </li>
        )
      }),
    [Renderer, content],
  )

  if (!content?.length) {
    return null
  }

  return (
    <div
      {...storyblokEditable(blok)}
      className={styles.slider}
      style={
        {
          '--cms-slider-gap-desktop': getNonEmptyStyleStringValue(desktopGap),
          '--cms-slider-gap-mobile': getNonEmptyStyleStringValue(mobileGap),
          '--cms-slider-gap-tablet': getNonEmptyStyleStringValue(tabletGap),
          '--cms-slider-items-per-page-desktop':
            getNonEmptyStyleStringValue(desktopItemsPerPage),
          '--cms-slider-items-per-page-mobile':
            getNonEmptyStyleStringValue(mobileItemsPerPage),
          '--cms-slider-items-per-page-tablet':
            getNonEmptyStyleStringValue(tabletItemsPerPage),
        } as CSSProperties
      }
    >
      <ul className={styles.slider__container} ref={containerRef}>
        {slides}
      </ul>
      <SliderButton
        aria-label="previous"
        className={clsx(styles['slider__navigation-button'], 'left-0')}
        direction="left"
        ref={leftRef}
      />
      <SliderButton
        aria-label="next"
        className={clsx(styles['slider__navigation-button'], 'right-0')}
        direction="right"
        ref={rightRef}
      />
    </div>
  )
}
